<template>
  <div class="infoNavigation">
    <div class="box">
      <template v-for="(item, index) in list" :key="index">
        <van-cell
          @click="JumpLink(item)"
          :title="item.processName"
          :value-class="!item.processState ? '' : 'blueText'"
          is-link
          :value="item.val"
        />
      </template>

      <div class="base-footer-text">
        <van-checkbox
          icon-size="20px"
          checked-color="#8486F8"
          v-model="checked"
        />
        <div class="base-text-p" @click="lookProtocol">查看授信相关协议</div>
      </div>
      <!-- handleSubmit -->
      <!-- handleLive -->
      <div v-if="env == 'APP'" @click="handleLive" :class="checked ? 'subBtn' : 'subBtn1'">
        同意协议并提交
      </div>
      <div v-else  @click="handleSubmit" :class="checked ? 'subBtn' : 'subBtn1'">
        同意协议并提交
      </div>

      <div class="i-back">
        <span @click="handleJumpIndex">回到首页</span>
      </div>
    </div>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>

<script>
import { onMounted, reactive, toRefs, onUnmounted } from "vue";
import { Jump } from "@/utils/routh";
import { isEmptyObj } from "@/utils/index";
import API from "@/server/api";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const env = localStorage.getItem("env") || 'h5'
    const Route = useRoute();
    const Router = useRouter();
    const state = reactive({
      isLoading: false,
      list: [],
      canSubmit: false,
      creditNo: "",
      checked: false,
      key: null,
    });

    // 获取认证列表状态
    const init = async () => {
      try {
        const result = await API.getUserStatus({ creditNo: state.creditNo });
        if (result.success) {
          console.log(result, 'result');
          // 如果是 APP 去除 processId == 1
          if (env == 'APP') {
            result.data = result.data.filter((item) => item.processId !== 1);
          }
          result.data.forEach((item) => {
            switch (item.processId) {
              case 1:
                item.val = "未认证";
                if (item.processState) item.val = "已认证";
                break;
              case 2:
              case 3:
                item.val = "未填写";
                if (item.processState) item.val = "已填写";
                break;
              case 4:
                item.val = "未上传";
                if (item.processState) item.val = "已上传";
                break;
            }
          });

          state.list = result.data;

          let bool = true;
          result.data.forEach((item) => {
            if (item.processState === 0) bool = false;
          });
          state.canSubmit = bool;
        }
        console.log(state,'--state--');
      } catch (err) {
        console.log(err);
      }
    };

    // 活体检测
    const handleLive = async () => {
      try {
        if (!state.canSubmit) {
          Toast('请先完成所有项目，在提交!')
          return
        }
        if (!state.checked) {
          Toast('请阅读并同意协议!')
          return
        }
        // key == 2 提额，提额不要调人脸
        if (state.key == 2) { 
          handleSubmit()
          return
        }
        uni.postMessage({
          data: {
            action: "checkLiveAuth"
          }
        })
        const { code, data } = await API.getliveAuth();
        if (code === "200") {
          // 获取 flowId 存到本地缓存
          localStorage.setItem("flowId", data.flowId);
          window.location.href = data.authUrl;
        }
      } catch (error) {
        return false;
      }
    };
    // 提交
    const handleSubmit = async () => {
      // APP 不用校验
      if (env != 'APP') { 
        await init();
        if (!state.canSubmit) {
          Toast('请先完成所有项目，在提交!')
          return
        }
        if (!state.checked) {
          Toast('请阅读并同意协议!')
          return
        }
      }
      let res;
      console.log(state.key, "key");
      switch (state.key) {
        case "1":
          try {
            res = await API.gotoLoan({
              creditNo: state.creditNo,
              jsonObject: {},
            });

            if (res.success) {
              Toast("提交成功");
              setTimeout(function () {
                // Router.replace("/index");
                if (env === 'APP') { 
                  // 只有 ios 才需要
                  let userAgent = navigator.userAgent
                  if (userAgent.match(/iPhone|iPad|iPod/i)) {
                    Router.go(-3)
                    return
                  }
                  Router.go(-1)
                  return
                }
                Router.replace("/index");
              }, 1000);
            }
          } catch (err) {
            Toast(err.message);
            console.log(err.message);
          }
          break;
        case "2":
          try {
            res = await API.subAddMoney();
            if (res.success) {
              Toast("提交成功");
              localStorage.setItem("keys", "");
              setTimeout(function () {
                Router.go(-1)
                // Router.replace("/index");
              }, 1000);
            }
          } catch (err) {
            Toast(err.message);
            console.log(err.message);
          }
          break;
      }
    };

    // 跳转到协议列表
    const lookProtocol = () => {
      Jump("/loanProtocol", { num: 4 });
    };

    onMounted(async () => {
      const newKey = localStorage.getItem("keys");
      if (newKey) state.key = newKey;
      state.creditNo = localStorage.getItem("creditNo");
      await init();
      if (!isEmptyObj(Route.query)) {
        const { key } = Route.query;
        state.key = key;
        // 判断是否认证成功 认证成功之后直接调用提交按钮
        // 防止 APP 或者浏览器返回
        // let faceSuccess = localStorage.getItem("faceSuccess") || "";
        // console.log(success, 'success');
        // console.log(faceSuccess, 'faceSuccess');
        // if (success == 1 || faceSuccess == 1) {
        //   state.checked = true;
        //   await handleSubmit();
        // }
      }
      if (env == 'APP') { 
        const { success } = Route.query;
        let faceSuccess = localStorage.getItem("faceSuccess") || "";
        if (success == 1 || faceSuccess == 1) {
          state.checked = true;
          localStorage.removeItem("faceSuccess");
          await handleSubmit();
        }
      }
    });

    onUnmounted(() => {
      localStorage.removeItem("faceSuccess");
    });

    // 链接跳转
    const JumpLink = async (item) => {
      console.log(item, "item");

      switch (item.processId) {
        case 1:
          if (!item.processState) {
            try {
              // 新诺韦尔
              if (env != 'APP') {
                const res = await API.getliveToken()
                if (res.success) {
                  const {bizToken} = res.data
                  // console.log(`https://api.megvii.com/faceid/lite/do?token=${bizToken}`,'url')
                  window.location.href = `https://api.megvii.com/faceid/lite/do?token=${bizToken}`
                }
                return
              }
              // E签宝
              const { code, data } = await API.getliveAuth();
              if (code === "200") {
                // 获取 flowId 存到本地缓存
                localStorage.setItem("flowId", data.flowId);
                window.location.href = data.authUrl;
              }
            } catch (err) {
              Toast(err.message);
              console.log(err);
            }
          }
          break;
        case 2:
          if (!item.processState) {
            Jump("/immediateStaging", {
              key: 0,
              creditType: "NORMAL_CREDIT",
              edit: true,
            });
          } else if (item.memo === "NORMAL_CREDIT") {
            Jump("/immediateStaging", {
              key: 1,
              creditType: item.memo,
              edit: true,
            });
          } else if (item.memo === "ADD_CREDIT") {
            Jump("/immediateStaging", {
              key: 1,
              creditType: item.memo,
              edit: true,
            });
          }
          // Jump(`/immediateStaging`, {key: 0})
          break;
        case 3:
          if (!item.processState) {
            Jump("/baseInfo", { key: 0 });
          } else if (item.processState === -1 || item.processState === 1) {
            Jump("/baseInfo", { key: 0 });
          }
          break;
        case 4:
          if (!item.processState) {
            Jump("/uploadImg");
          } else if (item.processState === -1 || item.processState === 1) {
            Jump("/uploadImg");
          }
          break;
      }
    };
    // 回到首页
    const handleJumpIndex = () => {
      Router.replace("/index");
    };
    // ios跳转
    // con

    return {
      env,
      ...toRefs(state),
      lookProtocol,
      handleSubmit,
      JumpLink,
      handleJumpIndex,
      handleLive,
    };
  },
};
</script>
